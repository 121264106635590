import "./Main.css";
import React from "react";
import { defaultImgs } from "../defaultimgs";
import { TextArea, Icon } from "web3uikit";
import { useState, useRef, useEffect } from "react";
import Post from "./Post";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";

const Main = () => {
  // moralis.io setup
  const { Moralis, isInitialized, isAuthenticated, account } = useMoralis();
  const user = Moralis.User.current();
  const contractProcessor = useWeb3ExecuteFunction();

  // States

  const [selectedFile, setSelectedFile] = useState();
  const [theFile, setTheFile] = useState();
  const [post, setPost] = useState();
  const [userprof, setUserprof] = useState();

  // Refs

  const inputFile = useRef(null);

  // Functions

  const fetchUserData = async () => {
    // 全てのユーザープロフィールデータをコントラクトから取得しuserprofステートに保存
    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "getAllUsers",
      abi: [{
        "inputs": [],
        "name": "getAllUsers",
        "outputs": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "bio",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "pfp",
                "type": "string"
              }
            ],
            "internalType": "struct TestSNS_05.User[]",
            "name": "",
            "type": "tuple[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
      params: {
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: (results) => {
        console.log("getAllUsers@Main Success");
        console.log(results);
        const mappedResults = new Map( // アドレスで参照しやすいようにアドレスをキーにしたMapに変換
          results.map(e => {
            return [e.userAddress.toLocaleLowerCase(), { name: e.name, bio: e.bio, pfp: e.pfp }]; // マッチング用に小文字変換
          }),
        );
        console.log(mappedResults);
        setUserprof(mappedResults);
      },
      onError: (error) => {
        console.log("getAllUsers@Main Error");
        console.log(error.message);
      }
    });
  };

  const addPost = async () => {
    // 投稿メッセージと画像（IPFSリンク）をコントラクトに保存
    if (!post) return;

    let img;
    if (theFile) {
      const data = theFile;
      const file = new Moralis.File(data.name, data);
      await file.saveIPFS();
      img = file.ipfs();
    } else {
      img = "No Img"
    }

    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "addPost",
      abi: [{
        "inputs": [
          {
            "internalType": "string",
            "name": "_txt",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "_img",
            "type": "string"
          }
        ],
        "name": "addPost",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }],
      params: {
        _txt: post,
        _img: img,
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        console.log("addPost Success");
        window.location.reload();
      },
      onError: (error) => {
        console.log("addPost Error");
        console.log(error.message);
      }
    });
  }

  const onImageClick = () => {
    inputFile.current.click();
  };

  const changeHandler = (event) => {
    const img = event.target.files[0];
    setTheFile(img);
    setSelectedFile(URL.createObjectURL(img));
  };

  // Effects

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      fetchUserData();
    }
  }, [isAuthenticated, isInitialized, account]);

  // Render

  return (
    <>
      <div className="pageIdentify">Main</div>
      <div className="mainContent">
        <div className="profilePost">
          <div class="tooltip">
            <img src={userprof ? (userprof.get(account) ? ((userprof.get(account).pfp ? userprof.get(account).pfp : defaultImgs[0])) : defaultImgs[0]) : defaultImgs[0]} className="profilePic"></img>
            <span class="tooltiptext">
              {userprof ? (userprof.get(account) ? ((userprof.get(account).bio ? userprof.get(account).bio : "")) : "") : ""}
            </span>
          </div>
          <div className="postBox">
            <TextArea
              label=""
              name="postTxtArea"
              value="GM World"
              type="text"
              onChange={(e) => setPost(e.target.value)}
              width="95%"
            ></TextArea>
            {selectedFile && (
              <img src={selectedFile} className="postImg"></img>
            )}
            <div className="imgOrPost">
              <div className="imgDiv" onClick={onImageClick}>
                <input
                  type="file"
                  name="file"
                  ref={inputFile}
                  onChange={changeHandler}
                  style={{ display: "none" }}
                />
                <Icon fill="#1DA1F2" size={20} svg="image"></Icon>
              </div>
              <div className="postOptions">
                <div className="post" onClick={addPost} style={{ backgroundColor: "#8247e5" }}>
                  <Icon fill="#ffffff" size={20} svg="matic" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Post />
      </div>
    </>
  );
};

export default Main;
