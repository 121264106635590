import React from "react";
import "./Menu.css";
import { Icon } from "web3uikit";
import { Link } from "react-router-dom";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { defaultImgs } from "../defaultimgs";

const Menu = () => {
    // moralis.io setup
    const { Moralis } = useMoralis();
    const user = Moralis.User.current();
    const contractProcessor = useWeb3ExecuteFunction();

    // Render

    return (
        <>
            <div className="siderContent">
                <div className="menu">
                    <div className="details">
                        <Icon fill="#ffffff" size={33} svg="twitter" />
                    </div>

                    <Link to="/" className="link">
                        <div className="menuItems">
                            <Icon fill="#ffffff" size={33} svg="list" />
                            Home
                        </div>
                    </Link>

                    <Link to="/settings" className="link">
                        <div className="menuItems">
                            <Icon fill="#ffffff" size={33} svg="cog" />
                            Settings
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Menu;
