import './App.css';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { useMoralis } from "react-moralis";
import { ConnectButton, Icon } from "web3uikit";
import Main from "./pages/Main";
import Menu from "./pages/Menu";
import Settings from "./pages/Settings";

const App = () => {
  // moralis.io setup
  const { isAuthenticated, Moralis } = useMoralis();

  // Render

  return (
    <>
      {isAuthenticated ? (
        <div class="bg-blue-900">
          <div class="flex">
            <div class="w-1/5 text-white h-12 pl-32 py-4 h-screen sticky top-0">
              <Menu />
              <div
                className="logout"
                onClick={() => {
                  Moralis.User.logOut().then(() => {
                    window.location.reload();
                  });
                }}
              >
                Logout
              </div>
            </div>
            <div class="w-4/5 border border-gray-600 h-auto  border-t-0">
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/settings" element={<Settings />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
          <div class="bg-blue-900">
            <div className="login">
              <Icon fill="#ffffff" size={40} svg="twitter" />
              <ConnectButton />
            </div>
          </div>
      )}
    </>
  );
}

export default App;
