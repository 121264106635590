import React from "react";
import "./Settings.css";
import { useState, useRef, useEffect } from "react";
import { Input } from "web3uikit";
import { useMoralis, useMoralisWeb3Api, useWeb3ExecuteFunction } from "react-moralis";

const Settings = () => {
  // moralis.io setup
  const { Moralis, isInitialized, isAuthenticated, account } = useMoralis();
  const Web3Api = useMoralisWeb3Api();
  const contractProcessor = useWeb3ExecuteFunction();

  // States

  const [pfps, setPfps] = useState([]);
  const [selectedPFP, setSelectedPFP] = useState();
  const [username, setUsername] = useState();
  const [bio, setBio] = useState();

  // Functions

  const resolveLink = (url) => {
    if (!url || !url.includes("ipfs://")) return url;
    return url.replace("ipfs://", "https://gateway.ipfs.io/ipfs/");
  };

  const fetchNFTs = async () => {
    // プロフィールアイコン用にユーザーのNFTを全て取得しpfpsステートに保存
    const options = {
      chain: "mumbai",
      address: account
    }

    const mumbaiNFTs = await Web3Api.account.getNFTs(options);
    const images = mumbaiNFTs.result.map(
      (e) => resolveLink(JSON.parse(e.metadata)?.image)
    );
    console.log("getNFTs Success")
    console.log(images);
    setPfps(images);
  };

  const saveEdits = async () => {
    // プロフィールの変更をコントラクトに保存
    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "updateUser",
      abi: [{
        "inputs": [
          {
            "internalType": "string",
            "name": "_name",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "_bio",
            "type": "string"
          },
          {
            "internalType": "string",
            "name": "_pfp",
            "type": "string"
          }
        ],
        "name": "updateUser",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }],
      params: {
        _name: username,
        _bio: bio,
        _pfp: selectedPFP,
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        console.log("updateUser Success");
        window.location.reload();
      },
      onError: (error) => {
        console.log("updateUser Error");
        console.log(error.message);
      }
    });

    window.location.reload();
  }

  // Effects

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      fetchNFTs();
    }
  }, [isAuthenticated, isInitialized, account]);

  // Render

  return (
    <>
      <div className="pageIdentify">Settings</div>
      <div className="settingsPage">
        <Input
          label="Name"
          name="NameChange"
          width="100%"
          labelBgColor="#141d26"
          onChange={(e) => setUsername(e.target.value)}
        />

        <Input
          label="Bio"
          name="bioChange"
          width="100%"
          labelBgColor="#141d26"
          onChange={(e) => setBio(e.target.value)}
        />

        <div className="pfp">
          Profile Image (Your NFTs)

          <div className="pfpOptions">
            {pfps.map((e, i) => {

              return (
                <>
                  <img
                    src={e}
                    className={
                      selectedPFP === e ? "pfpOptionSelected" : "pfpOption"
                    }
                    onClick={() => { setSelectedPFP(pfps[i]); }}
                  ></img>
                </>
              )
            })}
          </div>
        </div>

        <div className="save" onClick={() => saveEdits()}>
          Save
        </div>
      </div>
    </>
  );
};

export default Settings;
