import React from "react";
import "./Post.css";
import { defaultImgs } from "../defaultimgs";
import { Icon } from "web3uikit";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useEffect, useState } from "react";

const Post = () => {
  // moralis.io setup
  const { Moralis, isInitialized, isAuthenticated, account } = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();

  // States

  const [posts, setPosts] = useState();
  const [userprof, setUserprof] = useState();
  const [isSortById, setIsSortById] = useState(true);

  // Functions

  const getPosts = async () => {
    // 全ての投稿データをコントラクトから取得しpostsステートに保存
    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "getAllPosts",
      abi: [{
        "inputs": [],
        "name": "getAllPosts",
        "outputs": [
          {
            "components": [
              {
                "internalType": "uint256",
                "name": "id",
                "type": "uint256"
              },
              {
                "internalType": "address",
                "name": "poster",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "txt",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "img",
                "type": "string"
              },
              {
                "internalType": "uint256",
                "name": "favoriteCount",
                "type": "uint256"
              },
              {
                "internalType": "uint256",
                "name": "submittedTimestamp",
                "type": "uint256"
              }
            ],
            "internalType": "struct TestSNS_05.Post[]",
            "name": "",
            "type": "tuple[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
      params: {
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: (results) => {
        console.log("getAllPosts Success");
        console.log(results);
        const modifiedResults = results.map(e => {
          return {
            id: e.id,
            poster: e.poster.toLocaleLowerCase(), // マッチング用に小文字変換
            txt: e.txt,
            img: e.img,
            favoriteCount: e.favoriteCount,
            submittedTimestamp: new Date(e.submittedTimestamp * 1000), // 日時フォーマット変換
          };
        });
        console.log(modifiedResults);
        setPosts(modifiedResults);
      },
      onError: (error) => {
        console.log("getAllPosts Error");
        console.log(error.message);
      }
    });
  };

  const fetchUserData = async () => {
    // 全てのユーザープロフィールデータをコントラクトから取得しuserprofステートに保存
    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "getAllUsers",
      abi: [{
        "inputs": [],
        "name": "getAllUsers",
        "outputs": [
          {
            "components": [
              {
                "internalType": "address",
                "name": "userAddress",
                "type": "address"
              },
              {
                "internalType": "string",
                "name": "name",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "bio",
                "type": "string"
              },
              {
                "internalType": "string",
                "name": "pfp",
                "type": "string"
              }
            ],
            "internalType": "struct TestSNS_05.User[]",
            "name": "",
            "type": "tuple[]"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
      params: {
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: (results) => {
        console.log("getAllUsers@Post Success");
        console.log(results);
        const mappedResults = new Map( // アドレスで参照しやすいようにアドレスをキーにしたMapに変換
          results.map(e => {
            return [e.userAddress.toLocaleLowerCase(), { name: e.name, bio: e.bio, pfp: e.pfp }]; // マッチング用に小文字変換
          }),
        );
        console.log(mappedResults);
        setUserprof(mappedResults);
      },
      onError: (error) => {
        console.log("getAllUsers@Post Error");
        console.log(error.message);
      }
    });
  };

  const flipFavorite = async (id) => {
    // コントラクト上の、該当するユーザーアドレスと投稿IDのFavoriteのTrueとFalseを入れ替える
    let options = {
      contractAddress: "0xa06e5783297f6f64D6909DA21654dC9017e1653F",
      functionName: "flipFavorite",
      abi: [{
        "inputs": [
          {
            "internalType": "uint256",
            "name": "id",
            "type": "uint256"
          }
        ],
        "name": "flipFavorite",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }],
      params: {
        id: id,
      },
      msgValue: Moralis.Units.ETH(0),
    }

    await contractProcessor.fetch({
      params: options,
      onSuccess: () => {
        console.log("flipFavorite Success");
      },
      onError: (error) => {
        console.log("flipFavorite Error");
        console.log(error.message);
      }
    });
  };

  const flipSortMethod = () => {
    setIsSortById(isSortById ? false : true);
  }

  const sortFavorite = (a, b) => a.favoriteCount > b.favoriteCount ? 1 : -1;

  const sortId = (a, b) => a.id > b.id ? 1 : -1;

  // Effects

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      getPosts();
      fetchUserData();
    }
  }, [isAuthenticated, isInitialized, account]);

  // Render

  return (
    <>
      <div className="feedPost">
        <button type="button" onClick={flipSortMethod}>{isSortById ? "Sort: DATE" : "Sort: FAV"}</button>
      </div>
      {posts?.sort(isSortById ? sortId : sortFavorite).map((e) => {
        return (
          <>
            <div className="feedPost">
              <div class="tooltip">
                <img src={userprof ? (userprof.get(e.poster) ? ((userprof.get(e.poster).pfp ? userprof.get(e.poster).pfp : defaultImgs[0])) : defaultImgs[0]) : defaultImgs[0]} className="profilePic"></img>
                <span class="tooltiptext">
                  {userprof ? (userprof.get(e.poster) ? ((userprof.get(e.poster).bio ? userprof.get(e.poster).bio : "")) : "") : ""}
                </span>
              </div>
              <div className="completePost">
                <div className="who">
                  {userprof ? (userprof.get(e.poster) ? ((userprof.get(e.poster).name ? userprof.get(e.poster).name : e.poster.slice(0, 6))) : e.poster.slice(0, 6)) : e.poster.slice(0, 6)}
                  <div className="accWhen">{
                    `${e.poster} · 
                        ${e.submittedTimestamp.toLocaleString('ja-jp', { timeZone: 'UTC', timeZoneName: 'short' })}
                        `
                  }
                  </div>
                </div>
                <div className="postContent">
                  {e.txt}
                  {e.img && e.img != "No Img" && (
                    <img
                      src={e.img}
                      className="postImg"
                    ></img>
                  )}
                </div>
                <div className="interactions">
                  <div className="interactionNums" onClick={() => flipFavorite(e.id)}>
                    <Icon fill="#9f9f3f" size={20} svg="star" />
                    {`${e.favoriteCount}`}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }).reverse()}
    </>
  );
};

export default Post;
